input[type=text] {
    width: 100%;
    height: 36px;
    padding: 5px;
    font-size: 14px;
    border: none;
    background-color: var(--tg-theme-secondary-bg-color);
    border-radius: 5px;
    color: var(--tg-theme-text-color);
    line-height: 1.15;
    margin: 0;
}

.search-box {
    padding: 5px 15px;
}