/*
.store {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--tg-theme-button-color);
    padding: 15px;
}


.img {
    width: 100%;
    background: lightgray;
    height: 100px;
    margin: 0 auto;
    background: var(--tg-theme-button-color);
}

.description {
    font-size: 0.8em;
}

.add-btn {
    width: 100%;
}*/

.store-item {
    width: 100%;
}

.store-item .content {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x)* .5);
    padding-left: calc(var(--bs-gutter-x)* .5);
    margin-right: auto;
    margin-left: auto;
}

.store-item .info {
    text-align: left;
}

.store-item .link {
    flex-grow: 1;
    padding: 10px 0;
    text-decoration: none;
    display: flex;
    align-items: center
}

.store-item .link:active,.store-item .link:hover {
    color: var(--tg-theme-text-color)
}

.store-image {
    margin-right: 12px;
    max-width: 50px;
    max-height: 50px;
    border-radius: 10px;
    background: var(--tg-theme-button-color);
}

.store-name {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.1;
    color: var(--tg-theme-text-color);
    display: block
}

.store-category {
    font-size: 14px;
    line-height: 1.05;
    color: var(--tg-theme-hint-color);
    display: block
}

.store-item .line {
    --bs-gutter-x: 1.5rem;
    border-bottom: .5px solid var(--tg-theme-hint-color);
    margin-left: calc(62px + var(--bs-gutter-x) * .5)
}