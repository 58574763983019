.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

html {
  height: 100%;
  width: 100vw
}

#root {
  flex-grow: 1
}

.page-body {
  min-height: 100%;
  background-color: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color);
  font-family: SF Pro Text,Arial,Helvetica,"sans-serif";
  font-size: 14px;
  font-style: normal;
  overflow-x: hidden;
  display: flex;
  flex-direction: column
}

.app {
  position: relative;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  scrollbar-gutter: stable
}

img {
  max-width: 100%;
  height: auto
}

a {
  color: var(--tg-theme-link-color);
  text-decoration: none
}

a:hover,a:active {
  color: var(--tg-theme-link-color)
}

ul,ol {
  padding-left: 1rem
}

button {
  color: var(--tg-theme-text-color);
}

.page-body {
  min-height: 100%;
  background-color: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color);
  font-family: SF Pro Text, Arial, Helvetica, "sans-serif";
  font-size: 14px;
  font-style: normal;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
}
