.coupon-item .btn-main {
    width: 100%;
    height: 48px;
    font-size: 16px;
    font-weight: 600;
    color: var(--tg-theme-button-text-color);
    background-color: var(--tg-theme-button-color);
    border: none;
    border-radius: 10px;
}

.coupon-item {
    background-color: var(--tg-theme-secondary-bg-color);
    padding: 15px 8px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.coupon-item > h3 {
    font-size: 17px;
    line-height: 20px;
    text-align: left;
    margin-top: 0;
    margin-bottom: 8px;
}

.coupon-item .description {
    text-align: left;
    /* margin-bottom: 8px; */
    display: flex;
    font-size: 12px;
}

.coupon-item .btn-main:hover {
    color: var(--tg-theme-button-text-color);
    background-color: var(--tg-theme-button-color);
}

.promocode-box {
    display: flex;
    gap: 8px;
    margin-bottom: 10px;
}

.promocode-box .coupon-info {
    height: 58px;
    /*padding: 0 10px;*/
    background-color: var(--tg-theme-secondary-bg-color);
    border-radius: 10px;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.promocode-box .copy-button {
    width: 58px;
    height: 58px;
    padding: 0 10px;
    background-color: var(--tg-theme-secondary-bg-color);
    border-radius: 10px;
    border: none;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.promocode-box .code {
    color: var(--tg-theme-text-color);
    font-size: 15px;
    font-weight: 600;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.promocode-box .date {
    margin-left: 10px;
    color: var(--tg-theme-hint-color);

    text-align: right;
    width: 100%;
}

