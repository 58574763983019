.store-info {
    padding: 10px 0;
    display: flex;
}

.store-title {
    text-align: left;
    margin: 0;
}

.store-image {
    margin-right: 12px;
    max-width: 50px;
    max-height: 50px;
    border-radius: 10px;
    background: var(--tg-theme-button-color);
}

.store-description {
    font-size: 14px;
    display: block;
    padding: 10px 0;
    text-align: left;
}